import router from "./router";
let hostname = window.location.hostname;
let mobileDomain,desktopDomain;

const isMobile = () => /mobile/i.test(navigator.userAgent);
let searchString = ["/login", "/workDetails", "/share"];
// const mobileDomain = "http://192.168.0.19:8080";
// const desktopDomain = "http://192.168.0.19:8081";
if (hostname == "192.168.0.110") {
   mobileDomain = "http://192.168.0.110:8889"; //h5
   desktopDomain = "http://192.168.0.110:8888"; //pc
} else {
   mobileDomain = "https://m.aimber.cn";
   desktopDomain = "https://aimber.cn";
}

router.beforeEach(async (to, from, next) => {
  if (isMobile()) {
    // 在移动设备上，跳转到移动域名
    if (window.location.origin !== mobileDomain) {
      // next({ path: mobileDomain + to.fullPath })
      if (searchString.includes(to.path)) {
        window.location.href = mobileDomain + to.fullPath;
      } else {
        window.location.href = mobileDomain;
      }
      next();
      // next(mobileDomain + to.fullPath);
    } else {
      next();
    }
  } else {
    // 在桌面设备上，跳转到桌面域名
    if (window.location.origin !== desktopDomain) {
      // window.location.href = desktopDomain + to.fullPath;
      if (searchString.includes(to.path)) {
        window.location.href = desktopDomain + to.fullPath;
      } else {
        window.location.href = desktopDomain;
      }
      next();
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
});
